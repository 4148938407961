.Modal {
    /* display: none; */
    opacity: 0;
    z-index: -999;
    transition: opacity 0.25s ease;
    top: -3000px;
    width: 1px;
    height: 1px;
    display: none;
}

.Modal.active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    height: 100vh;
    display: block;
    background: rgba(0, 0, 0, 0.75);
    padding: 10px;
    z-index: 999;
    opacity: 1;
    width: auto;
    /* height: auto; */
}

.ModalWrapper {
    width: auto;
    max-width: '100%';
    padding: 20px 0px 0px;
    background: #FFFFFF;
    border-radius: 5px;
    /* overflow-y: scroll; */
    /* max-height: 80vh; */
    position: relative;
    margin: 1.75rem auto;
}

.ModalContent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
}

.ModalBody {
    position: relative;
    flex: 1 1 auto;
}

.close {
    position: absolute;
    /* right: 5px;
    top: 5px; */
    right: 10px;
    top: 10px;
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .ModalContent {
        padding: 0px;
    }
}