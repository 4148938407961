.preBlockWrapper {
    width: auto;
    max-width: 500px;
    background: #f9f9f9;
    border-radius: 5px;
    /* overflow-y: scroll; */
    /* max-height: 80vh; */
    position: relative;
    margin: 1.75rem auto;
}

.preBlockContent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    background-color: #FEF200;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 30px;
    outline: 0;
}

.preBlockBody {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding:20px;
    gap: 10px;
}

.preBlockTitle {
	font-family: 'Tahoma';
	font-size: 72px;
	line-height: 87px;
	text-transform: uppercase;
	color: #282828;
}
.preBlockHeader {
	border-bottom: none!important;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.preBlockTitleImg {
	align-self: self-start;
}
.jivakaid {
	font-family: 'Tahoma';
	font-size: 18px;
	line-height: 22px;
	text-align: right;
	color: #000000;
}
.preBlockBrandInput {
	width: 100%;
    background: transparent;
	border: none;
    border-bottom: 1px solid #000000;
}
.preBlockBrandButton {
	width: 100%;
	background: #02833d;
	border-radius: 10px;
	font-family: 'Tahoma';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	    display: block;
    padding: 10px 0;
    margin-top: 20px;
}