body {
	font-family: 'Montserrat';
	font-style: normal;
	background: #F5F5F5;
	font-weight: 400;
}

.container-fluid {
	max-width: 1320px;
}


.fixed-header {
	position: fixed;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.9);
	height: 130px;
	display: flex;
	align-items: center;
	z-index: 1;
}

@media (max-width: 992px) {
	.fixed-header {
		height: 100px;
	}
}

.fixed-header .fixed-header__inner {
	/* max-width: 1198px; */
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}


.fixed-header .fixed-header__button-holder {
	width: 100%;
	max-width: 222px;
}

@media (max-width: 992px) {
	.fixed-header .fixed-header__button-holder {
		width: 100%;
		max-width: 182px;
	}
}

.fixed-header .fixed-header__button {
	display: block;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	padding: 20px;
	text-decoration: none;
	background: #02833d;
	border-radius: 30px;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 25px;
	color: #fefefe;
	text-decoration: none;
	transition: .3s;
	opacity: 1;
}

.fixed-header .fixed-header__button:hover {
	opacity: 0.7;
	transition: .3s;
}

@media (max-width: 1200px) {
	.fixed-header .fixed-header__button {
		padding: 10px 20px;
	}
}

.opHover {
	text-decoration: none;
	transition: .3s;
	opacity: 1;
}

.opHover:hover {
	opacity: 0.8;
	transition: .3s;
}

.firstBlock {
	width: 100%;
	height: 100vh;
	background: url("../../assets/images/firstBg.jpg") no-repeat;
	background-size: cover;
	position: relative;
	display: flex;
	justify-content: center;
}

.firstBlock h1 {
	font-weight: 700;
	font-size: 135px;
	line-height: 130px;
	text-align: center;
	color: #FFFFFF;
	margin-bottom: 150px;
	margin-top: 70px;
}

@media (max-width: 1300px) {
	.firstBlock h1 {
		font-weight: 700;
		font-size: 90px;
		line-height: 110px;
		text-align: center;
		color: #FFFFFF;
		margin-bottom: 150px;
	}
}

@media (max-width: 500px) {

	.firstBlock h1 {
		font-size: 42px;
		line-height: 50px;
	}
}

.firstBlock p {
	font-family: 'Proxima Nova';
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: #FFFFFF;
	margin-top: 50px;
}

.firstBlockInner {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

@media (max-width: 340.98px) {
	.firstBlockInner {
		justify-content: flex-start;
		padding-top: 20px;
	}
}

.firstBlockTop {
	display: flex;
	gap: 10px;
	margin-top: 25px;
}

.firstBlockTop .donationButton {
	height: 150px;
	width: 150px;
}

.logo {
	text-align: center;
}

.donationButtonWrap {
	/*background: #FEF200;
	border-radius: 30px;
	padding: 10px;*/
}

.donationButton {
	background: #02833d;
	border-radius: 30px;
	padding: 10px;
	display: flex;
	flex-direction: column;

	font-family: 'Proxima Nova';
	font-size: 21px;
	line-height: 25px;
	text-transform: uppercase;
	color: #282828;

	height: 100%;
	justify-content: space-around;
	text-decoration: none;
}

.donationButton:hover {
	text-decoration: underline;
	color: #282828;
}

.donationButton img {
	width: 48px;
	align-self: flex-end;
}

.donationDescription {
	font-family: 'Proxima Nova';
	font-weight: 700;
	font-size: 21px;
	line-height: 25px;
	color: #FFFFFF;
	background: #04833E;
	border-radius: 30px;
	padding: 15px;
}

p {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 25px;
	color: #231F20;
}


.full-green-block {
	background: #04833E;
}

.secondBlock {
	margin-bottom: 120px;
}

.secondBlock p {
	max-width: 532px;
}

.secondBlockInner {
	margin-top: 144px;
}

.secondBlockInner h2 {
	font-family: 'Helvetica';
	font-weight: 700;
	font-size: 72px;
	line-height: 83px;
	color: #000000;
	margin-bottom: 23px;
}

.secondBlockInner h3 {
	/* font-family: 'Proxima Nova'; */
	font-size: 48px;
	line-height: 58px;
	color: #000000;
	margin-bottom: 60px;
}

.secondBlockImgs {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	align-items: flex-end;
}

@media (max-width: 767.98px) {
	.secondBlockImgs {
		align-items: center;
	}
}

.secondBlock__info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

@media (max-width: 767.98px) {
	.secondBlock__info {
		max-width: 500px;
		margin: 0 auto 50px;
	}
}

.secondBlockImgs img {
	width: 90%;
	max-width: 500px;
}

@media (max-width: 767.98px) {
	.secondBlockImgs img {
		width: 100%;
		max-width: 400px;
	}
}

.thirdBlock {
	margin-top: 50px;
}

.thirdBlock h2 {
	font-weight: 700;
	font-size: 48px;
	line-height: 55px;
	color: #231F20;
	max-width: 493px;
}

.thirdBlock p {
	font-size: 21px !important;
}

.thirdBlock h2+p {
	margin-top: 40px;
	max-width: 437px;
}

.thirdBlock img {
	max-width: 540px;
}

@media (max-width: 767.98px) {
	.thirdBlock {
		max-width: 540px;
		text-align: center;
		margin: 0 auto 100px;
	}

	.thirdBlock h2 {
		margin: 0 auto;
	}

	.thirdBlock h2+p {
		margin: 40px auto;
	}
}

.fifthBlock {
	/* margin-top: 60px; */
}

.full-green-block__fifth {
	background: #000000;

	padding: 40px 0;
}

.fifthBlockInner {
	/* background: url("../../assets/images/bgHand.png") no-repeat;
	background-size: cover; */
	padding: 40px;
	/* height: 875px;
	border-radius: 30px;
	display: flex;
	align-items: flex-end; */
}

.fifthBlockInner h2 {
	font-weight: 700;
	font-size: 48px;
	line-height: 55px;
	color: #FFFFFF;
	margin-bottom: 40px;
}

.fifthBlockInner p {
	font-family: 'Proxima Nova';
	font-size: 21px;
	line-height: 25px;
	color: #FFFFFF;
}

.fifthBlock__img {
	width: 100%;
}

@media (max-width: 767.98px) {

	.fifthBlock__img {
		width: 100%;
		margin-top: 50px;
	}
}

.sixthBlock {
	margin-top: 60px;
}

.sixthBlock h2 {
	font-weight: 700;
	font-size: 48px;
	line-height: 55px;
	color: #231F20;
}

.seventhBlock {
	margin-top: 60px;
}

.seventhBlock h2 {
	font-weight: 700;
	font-size: 48px;
	line-height: 55px;
	color: #231F20;
}

.full-green-block__fourth {
	padding: 40px 0;
}

.seventhBlock img {
	width: 100%;
}

.miniBlock {
	padding: 40px;
	margin-top: 40px;
	border-radius: 30px;
}

.miniBlock h2 {
	font-weight: 700;
	font-size: 48px;
	line-height: 55px;
	color: #FFFFFF;
}

.miniBlock p {
	font-family: 'Proxima Nova';
	font-size: 21px;
	line-height: 25px;
	color: #FFFFFF;
}

.full-green-block__second {
	margin-bottom: 170px;
}

.miniBlockOne {
	padding: 0 40px;
	background: url("../../assets/images/leafs.png") no-repeat;
	background-size: cover;
	background-size: 765px auto;
	background-position-x: right;
	background-repeat: no-repeat;
	background-position-y: bottom;
	margin-top: 0;
}

.miniBlockOne p {
	margin-top: 50px;
	max-width: 437px;
}

.miniBlockOne h2 {
	margin-top: 50px;
}

.miniBlockTwo img {
	width: 100%;
}

@media (max-width: 767.98px) {
	.miniBlockTwo img {
		margin-top: 50px;
	}
}

.miniBlockTwo h2 {
	margin-bottom: 40px;
}

.greenP {
	font-family: 'Proxima Nova';
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: #04833E;
}

.blackP {
	font-family: 'Proxima Nova';
	font-size: 21px;
	line-height: 25px;
	color: #231F20;
}

.footer_cont {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	margin-top: 100px;
	margin-bottom: 100px;
}

.footer_cont p {
	font-family: 'Proxima Nova';
	font-weight: 400;
	font-size: 21px;
	line-height: 25px;
	color: #000000;
}

.brandModal .modal-content {
	background: #FEF200;
	border-radius: 30px;
}

.brandModal .modal-title {
	font-family: 'Proxima Nova';
	font-size: 72px;
	line-height: 87px;
	text-transform: uppercase;
	color: #282828;
}

.brandModal .modal-header {
	border-bottom: none !important;
}

.brandModal .modal-header img {
	align-self: self-start;
}

.brandModal .jivakaid {
	font-family: 'Proxima Nova';
	font-size: 18px;
	line-height: 22px;
	text-align: right;
	color: #000000;
}

.brandModal .brandInput {
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: 1px solid #000000;
}

.brandModal .brandButton {
	width: 100%;
	background: #000000;
	border-radius: 10px;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	display: block;
	padding: 10px 0;
	margin-top: 20px;
}

@media (max-width: 992px) {
	.donationButtonWrap {
		margin: 0 auto;
	}

	.miniBlock,
	.secondBlockImgs {
		gap: 20px;
		flex-direction: column;
	}

	.logo {
		width: 90px;
	}

	.logo img {
		width: 100%;
	}

	.donationButton {
		width: 150px;
		font-size: 15px;
		line-height: 25px;
	}

	.firstBlockTop .donationButton {
		height: 90px;
		width: 90px;
	}

	.donationButton img {
		width: 25px;
	}

	.donationBlock {
		margin-top: 20px;
		flex-direction: column-reverse;
	}

	.brandModal .modal-title {
		font-size: 36px;
		line-height: 50px;
	}

	.firstBlock h1 {
		font-size: 48px;
		line-height: 60px;
	}

	.firstBlock p {
		font-size: 18px;
		margin-top: 90px;
	}

	.secondBlockInner {
		margin-top: 50px;
	}

	.secondBlockInner h2 img {
		width: 200px;
	}

	.secondBlockInner h3 {
		font-size: 29px;
		line-height: 22px;
		margin-bottom: 45px;
	}

	.thirdBlock p {
		font-size: 18px !important;
	}

	.miniBlock h2 {
		font-size: 28px;
		line-height: 32px;
	}

	.miniBlock p {
		font-size: 15px;
		line-height: 23px;

	}

	.blackP,
	.greenP {
		font-size: 15px;
		line-height: 20px;
	}

	.fifthBlockInner h2 {
		font-size: 28px;
		line-height: 32px;
	}

	.fifthBlockInner p {
		font-size: 15px;
		line-height: 20px;
	}

	.thirdBlock h2,
	.sixthBlock h2,
	.seventhBlock h2 {
		font-size: 28px;
		line-height: 32px;
	}
}

.brandModal .brandInput {
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: 1px solid #000000;
}

.brandModal .brandInput:focus-visible {
	outline: none;
}

.brandModal .brandInput::placeholder {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.5);
}

.brandModal .jivakaid {
	font-family: 'Proxima Nova';
	font-size: 18px;
	line-height: 22px;
	text-align: right;
	color: #000000;
	max-width: 130px;
	margin-left: auto;
}

.brandModal .brandInput+.brandInput {
	margin-top: 20px;
}

.brandModal .brandButton {
	text-decoration: none;
}